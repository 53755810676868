import React, { Component } from 'react';
import '.././styles/main.css';
import { Helmet } from 'react-helmet';
import Login from './GoogleLoginPage';
import Card from 'react-bootstrap/Card';

class Kusunda extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            windowWidth: window.innerWidth, 
            searchTerms: '',
            isLoading: false,
            error: null
        };
    }
	
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}
	
	onKeyUp(e){
		if (e.charCode === 13) {
			window.location.href = '/search?q=' + e.target.value;
		  }
	}

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }
    
    render() {
        return (
            
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>LEARN KUSUNDA</title>
                    <link rel="canonical" href="https://kusunda.archivenepal.org" />
                    <meta name="description" content="Learn Kusunda Language" />
                </Helmet>
                <header id="header" className="alt" style={{position: 'fixed'}}>
                    <h1><img src="/images/archiveNepal.png" style={{ height: '35px'}} alt="Archive Nepal - Discover Nepal through its History"/></h1>
                </header>
            <div id="page-wrapper">
                <section id="main" className="container">
                <h2 style={{ height: '45px', marginTop: '-8px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}>Learn Kusunda Language</h2>          
                    <section className="box special features" style={{textAlign: 'left'}}>
                        <div style={{ height: '200px', border: '1px solid white', padding: '10px'}}>
                            <Card style={{ width: '360px', float:'left' }}>
                                <Card.Body>
                                    <Card.Title><a href='https://www.archivenepal.org/projectbanraja' target='_blank'>About the Project</a></Card.Title>
                                </Card.Body>
                            </Card>
                            <Card id='cardRight' style={{ width: '360px'}}>
                                <Card.Body>
                                    <Card.Title><a href='https://www.archivenepal.org/projectbanraja' target='_blank'>Syllabus</a></Card.Title>
                                </Card.Body>
                            </Card>
                        </div>
                        
                        <h1 style={{textAlign: 'center', marginBottom: '40px',}}>Sign in using your Google credentials to access this free course</h1>
                        <Login />

                        <div style={{marginTop: '100px'}}>
                        <h1 style={{marginTop: '40px'}}>About the Instructor</h1>
                        <img src='https://archivenepal.s3.us-east-1.amazonaws.com/images/udayRaj.png' style={{ width: '120px', float: 'left', padding: '10px'}}/>
                        <p style={{marginTop: '40px', fontSize: '18px'}}> Uday Raj Aaley is a freelance researcher, ethnographer, lexicographer, writer from Nepal who has worked on the Kusunda, Tharu, and Magar indigenous ethnic groups and their languages. He has been closely involved with the Kusunda since 2008 and published several books, textbooks, and articles on the people and their language. He is known for documenting and revitalizing the moribund Kusunda language and teaching it to Kusunda people. </p>

                        <h1 style={{marginTop: '40px'}}>About this course</h1>
                        <p style={{marginTop: '40px', fontSize: '18px'}}>This free 'Do at your own pace' Kusunda Language course was developed as part of Archive Nepal's project, Safeguarding the Kusunda (The Ban Rajas) Language and Culture for Future Generations. The Project was funded by the British Council's Cultural Protection Fund in partnership with the United Kingdom's Department for Digital, Culture, Media and Sport. </p>
                        <img src='https://archivenepal.s3.amazonaws.com/images/CPF_Header2.png' style={{ display:'block', marginLeft: 'auto', marginRight: 'auto', width: '75%'}}/>
                        </div>
                    </section>          
                </section>
            </div> 
            </div>
        )
    }
}
export default Kusunda;
