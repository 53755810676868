import React, { useState, useEffect, useRef } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import config from '../config/env.json';

export default function GoogleLoginPage() { 
  const [user, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  /*const user1 = [];
  const [email, setEmail] = useState(null); 
  const [name, setName] = useState([]);  */
  //const useremail = null ; 
  const fetchURL = "https://y6sfd318o5.execute-api.us-east-1.amazonaws.com/v1/users/";
  //const fetchURL = "https://hyt2czbda0.execute-api.us-east-1.amazonaws.com/v1/getAccessLink?username=snh.monish@gmail.com";
  const now = new Date();
  const userkeys = Object.values(user);
  const item = {
    expiry: now.getTime() + 3600000,
  }
  
  //const firstRender = useRef(true);

/*
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return; // Skip the first render
    }
    alert(email);
    fetch(fetchURL)
      .then((res) => { 
        return res.json();
      })
      .then((data) => {
     // console.log(data); 
      setData(data);
       localStorage.setItem('key', JSON.stringify(item))
       localStorage.setItem('user', JSON.stringify(name))
      })
      .catch((err) => {
        console.log(err);
      });
  }, [email]); */

  function verifyuser(useremail, username){
  /*  if (firstRender.current) {
      firstRender.current = false;
      return; // Skip the first render
    }*/
    fetch(fetchURL+useremail)
      .then((res) => res.json())
      .then((data) => {
     // console.log(data); 
      setData(data);
       localStorage.setItem('key', JSON.stringify(item))
       localStorage.setItem('user', JSON.stringify(username))
       localStorage.setItem('email', JSON.stringify(useremail))
      })
      .catch((err) => {
        localStorage.setItem('signupkey', JSON.stringify(item))
       localStorage.setItem('signupuser', JSON.stringify(username))
       localStorage.setItem('signupemail', JSON.stringify(useremail))
     // alert('Dear ' + username + '. You will be directed to the registration page to sign up for this course.');
      window.location.replace(config.REACT_ENV + 'courseregistration');
      });
  }

  const responseGoogle = (response) => {
      //console.log(response.credential);
      var decoded = jwt_decode(response.credential);
      //console.log(decoded);
     // setName(decoded.name);
      //setEmail(decoded.email);
      verifyuser(decoded.email, decoded.name);
    }
    
function datamap(){
  try {
  // console.log(Object.values(user1)[1]);
   // console.log(userkeys[1]);
   
    window.location.replace(config.REACT_ENV + userkeys[1]);
    //alert(userkeys[1]);    
  } catch(err) {
    alert(err);
    //alert('You are not signed up for');
  //  window.location.replace(config.REACT_ENV + 'mrms');
}
}

  return (
    <div className="">
          <div className="">
            <GoogleOAuthProvider 
                clientId="669728913801-6beq07brso86492q6qm2lpua8v3ttg12.apps.googleusercontent.com"
                >
         <GoogleLogin
              render={(renderProps) => (
                <button
                  type="button"
                  className=""
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <FcGoogle className="" /> Use google to sign in
                </button>
              )}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy="single_host_origin"
              />
            </GoogleOAuthProvider>
          </div>
          
          {
       userkeys[1] === undefined ? '' : datamap()
       //datamap()
        //console.log(userkeys[1])
     
      }
   
    </div>
  );
}