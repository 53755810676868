// App.js
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import Index from './components/index';
import SearchApp from './components/search';
import Objects from './components/digitalCollections/objectCollection';
import Treaties from './components/digitalCollections/treatiesCollection';
import AboutUs from './components/aboutUs';
import OurTeam from './components/ourTeam';
import ContactUs from './components/contactUs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import './components/styles/main.css';
import {FaTwitter, FaFacebook, FaInstagram, FaLinkedin} from 'react-icons/fa';
import Donate from '@mui/icons-material/VolunteerActivism';
import { slide as Menu} from 'react-burger-menu';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import BPBookCollection from './components/digitalCollections/BPBookCollection';
import digitalCollections from './components/digitalCollections';
import RedirectPage from './components/redirect';
import NepaliTimes from './components/digitalHimalaya/nepalitimes';
import Str from './components/str';
import DigitalHimalaya from './components/digitalHimalaya/main';
import NTUploader from './components/nepaliTimesUploader';
import RequestToIndex from './components/requesttoindex';
import FacesOfNepal from './components/digitalCollections/facesofnepal';
import IndraJatra from './components/digitalCollections/indrajatra';
import SupportUs from './components/supportUs';
import ElectionManifestos from './components/digitalCollections/2022ElectionManifestos';
import ElectionsInNepal from './components/digitalCollections/electionsinnepal';
import DirghamanandGaneshman from './components/digitalCollections/dirghamanandganeshman';
import SushilaSingh from './components/sushilasingh';
import Aerialviews from './components/digitalCollections/aerialviews';
import CurrentOpenings from './components/currentOpenings';
import WomenOfNepal from './components/onlineExhibits/womenofnepal';
import NTDataEntry from './components/ntDataEntry';
import TenttomGlenn from './components/tenttomglenn';
import Demo from './components/mrms/demo/demoSearch';
import RotaryKtm from './components/mrms/rotaryKtm/rotaryKtmSearch';
import MRMS from './components/mrms/manaslu';
import Login from './components/mrms/GoogleLoginPage';
import UNMembership from './components/onlineExhibits/unmembership';
import Treaty from './components/onlineExhibits/1923treaty';
import ProjectBanRaja from './components/digitalCollections/projectbanraja';
import Constitutions from './components/onlineExhibits/contitutions';
import GaneshPhotoLab from './components/digitalCollections/ganeshPhotoLab';
import KusundaCourse from './components/course/kusunda';
import KusundaLessons from './components/course/kusundalessons';
import Registration from './components/course/registration';

const theme = createTheme({
  palette:{
    nepalBlue: {
      main: '#0e4c8f',
      contrastText: '#fff',
    },
  },
});
class App extends Component {
  setGA = () =>{
    ReactGA.initialize('G-MVZTXE95T5');
    ReactGA.send('pageview');    
  };
  componentDidMount(){
    this.setGA();
  }
  render() {
    return (
      <div>
        <div className="App">
           <Helmet>
              <meta charset="utf-8" />
                  <title>Archive Nepal - Discover Nepal through its History.</title>
                  <link rel="canonical" href="https://www.archivenepal.org" />
                  <meta name="description" content="Archive Nepal, Inc. is a non profit corporation incorporated in New York, USA and currently pending its 501(c)(3) status. Our mission is to encourage the understanding of Nepal’s rich history and culture by curating, digitizing, and improving access to digital resources. " />
            </Helmet>
          <header className="App-header">
          </header>
        </div>
        <Router>
          <div>
            <header id="header" className="alt" style={{position: 'fixed'}}>
              <Menu>
                <span style={{borderBottom: '1px solid black', width: '150%'}}><a id="home" className="menu-item" style={{textDecoration: 'none', fontWeight: '100'}} href="/">Home</a> </span>
                <span style={{borderBottom: '1px solid black', width: '150%'}}><a id="about" className="menu-item" style={{textDecoration: 'none', fontWeight: '100'}} href="/aboutUs">About Us</a></span>
                <span style={{borderBottom: '1px solid black', width: '150%'}}><a id="team" className="menu-item" style={{textDecoration: 'none', fontWeight: '100'}} href="/ourTeam">Our Team</a></span>
                <span style={{borderBottom: '1px solid black', width: '150%'}}><a id="contact" className="menu-item" style={{textDecoration: 'none', fontWeight: '100'}} href="/contactUs">Contact Us</a></span> 
                <span style={{borderBottom: '1px solid black', width: '150%'}}><a id="contact" className="menu-item" style={{textDecoration: 'none', fontWeight: '100'}} href="/requesttoindex">Request to Index</a></span> 
                <span style={{borderBottom: '1px solid black', width: '150%'}}><a id="contact" className="menu-item" style={{textDecoration: 'none', fontWeight: '100'}} href="/currentopenings">Current Openings</a></span> 
                <span style={{borderBottom: '1px solid black', width: '150%'}}><a id="contact" className="menu-item" style={{textDecoration: 'none', fontWeight: '100'}} href="https://archivenepal.substack.com/" target="_blank">Newsletter</a></span> 
                <span><a id="donation" className="menu-item" style={{textDecoration: 'none', fontWeight: '100'}} href="https://www.archivenepal.org/supportus" >Support Our Work</a></span>
                <Login />
              </Menu>

              <h1><Link to={'/'} className="navbar-brand"><img src="/images/archiveNepal.png" style={{ height: '35px', marginTop: '-8px'}} alt="Archive Nepal - Discover Nepal through its History"/></Link></h1>
              <div style={{float: 'right', marginTop: '-6px'}}> 
                <div className='donate'>
                  <ThemeProvider theme={theme}>
                    <a href='https://donorbox.org/let-s-together-create-a-digital-archive-of-nepal' target='_blank'><Button color='nepalBlue' variant='contained' size='small' className='searchButton' startIcon={<Donate />}>Support Us</Button></a>
                  </ThemeProvider>
                </div>
                <div className='miniDonate'>
                  <a href='https://donorbox.org/let-s-together-create-a-digital-archive-of-nepal' target='_blank'><Donate /></a>
                </div>
              </div>
            {/*  <div className='donate'><Button variant='contained' size='small' className='searchButton' startIcon={<Donate />}> Donate</Button></div>
                <div className='miniDonate'><Link to=''><Donate /></Link></div>
    */}
              </header>
              
            <Switch>
            <Route exact path="/">
              <Redirect to="/home"></Redirect>
            </Route>
              <Route path='/home' component={Index} />
              <Route path='/search' component={SearchApp} />
              <Route path='/objectCollection' component={Objects} />
              <Route path='/treatiesCollection' component={Treaties} />
              <Route path='/aboutUs' component={AboutUs} />
              <Route path='/ourTeam' component={OurTeam} />
              <Route path='/contactUs' component={ContactUs} />
              <Route path='/BPBookCollection' component={BPBookCollection} />
              <Route path='/digitalCollections' component={digitalCollections} />
              <Route path='/nepalitimes' component={NepaliTimes} />
              <Route path='/str' component={Str} />
              <Route path='/digitalhimalaya' component={DigitalHimalaya} />
              <Route path='/ntUploader' component={NTUploader} />
              <Route path='/requesttoindex' component={RequestToIndex} />
              <Route path='/facesofnepal' component={FacesOfNepal}/>
              <Route path='/indrajatra' component={IndraJatra}/>
              <Route path='/supportus' component={SupportUs}/>
              <Route path='/electionmanifestos2022' component={ElectionManifestos}/>
              <Route path='/electionsinnepal' component={ElectionsInNepal}/>
              <Route path='/dirghamanganeshman' component={DirghamanandGaneshman}/>
              <Route path='/sushilasingh' component={SushilaSingh}/>
              <Route path='/aerialviews' component={Aerialviews}/>
              <Route path='/currentopenings' component={CurrentOpenings}/>
              <Route path='/womenofnepal' component={WomenOfNepal}/>
              <Route path='/ntDataEntry' component={NTDataEntry}/>
              <Route path='/tenttomglenn' component={TenttomGlenn}/>
              <Route path='/demo' component={Demo}/>
              <Route path='/rotaryktm' component={RotaryKtm}/>
              <Route path='/mrms' component={MRMS} />
              <Route path='/unmembership' component={UNMembership} />
              <Route path='/1923treaty' component={Treaty} />
              <Route path='/projectbanraja' component={ProjectBanRaja} />
              <Route path='/constitutions' component={Constitutions} />
              <Route path='/kusundacourse' component={KusundaCourse} />
              <Route path='/kusundalessons' component={KusundaLessons} />
              <Route path='/ganeshphotolab' component={GaneshPhotoLab} />
              <Route path='/courseregistration' component={Registration} />
              <Route path='*/:aliasId' component={RedirectPage} />
            </Switch>

            <footer id="footer">
            <section className="box special features" style={{textAlign: 'left'}}>    
                <div  className="features-row">
                  <section style={{marginRight: '20px'}}>
                    <h5>OUR MISSION</h5>
                    <p>Archive Nepal’s mission is to encourage the understanding of Nepal’s rich history and culture by curating, digitizing, and improving access to digital resources.</p>
                    <h5>FIND US ELSEWHERE</h5>   
                    <span className="icon minor accent2"><a href="https://www.facebook.com/archiveofnepal" target="_blank" style={{textDecoration: 'none'}}><FaFacebook size={20} /></a></span>   
                    <span className="icon minor accent2"><a href="https://www.instagram.com/archive_of_nepal/" target="_blank" style={{textDecoration: 'none'}}><FaInstagram size={20} /></a></span>  
                    <span className="icon minor accent2"><a href="https://www.twitter.com/archiveofnepal/" target="_blank" style={{textDecoration: 'none'}}><FaTwitter size={20} /></a></span>   
                    <span className="icon minor accent2"><a href="https://www.linkedin.com/company/archiveofnepal/" target="_blank" style={{textDecoration: 'none'}}><FaLinkedin size={20} /></a></span>    
                  </section>    
                  <section style={{width: '200px'}}>
                     <h5>MORE INFO</h5>
                    <a href='https://www.archivenepal.org/aboutUs' style={{textDecoration: 'none'}}><h4 style={{fontSize: '16px'}}>About Us</h4></a> 
                    <a href='https://www.archivenepal.org/ourTeam' style={{textDecoration: 'none'}}><h4 style={{fontSize: '16px'}}>Our Team </h4></a>
                    <a href='https://www.archivenepal.org/contactUs' style={{textDecoration: 'none'}}><h4 style={{fontSize: '16px'}}>Contact Us </h4></a>
                    <a href='https://www.archivenepal.org/requesttoindex' style={{textDecoration: 'none'}}><h4 style={{fontSize: '16px'}}>Request to Index</h4></a>
                    <ThemeProvider theme={theme}>
                    <a href='https://donorbox.org/let-s-together-create-a-digital-archive-of-nepal' target='_blank'><Button color='nepalBlue' variant='contained' size='large' className='searchButton' startIcon={<Donate />}>Support Us</Button></a>
                    </ThemeProvider>
                   </section>
                  
                </div>
              </section>
            </footer>

          </div>
        </Router>
      </div>

    );
  }
}
export default App;
