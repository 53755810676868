import React, { useState, useEffect, useRef } from 'react';
import '.././styles/main.css';
import { Helmet } from 'react-helmet';
import { Link } from '@material-ui/core';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import envConfig from '../config/env.json';

const theme = createTheme({
  palette:{
    nepalBlue: {
      main: '#0e4c8f',
      contrastText: '#fff',
    },
  },
});

function Expired(){
  alert('Your session has expired. Please sign in again.');
  localStorage.removeItem('user');
  localStorage.removeItem('key');
  window.location.replace(envConfig.REACT_ENV + 'kusundacourse');
}

export default function KusundaLessons() { 
    const [data, setData] = useState([]);
    const lessonURL = "https://archivenepal.s3.us-east-1.amazonaws.com/Kusunda/lessonvideos/";
    const fetchURL = "https://tgdjqjnd1m.execute-api.us-east-1.amazonaws.com/v1/lessons";
    const [lessonid, setLessonid] = useState('lesson1');
    const [lessonname, setLessonname] = useState('Kusunda Introduction');
    const [description, setDescription] = useState('Workers and communities in both our countries benefit from being each other’s biggest trading and security partner,” Canada’s prime minister wrote on social media. Trump’s musings on Tuesday came as he doubled down on threats to impose protectionist tariffs on one of the US’s biggest trading partners. [Total Videos: 1]');
    const [videoslist, setTotalVideos] = useState([{id: 'lesson1-1.mp4'}]);
    const [newvideos, setNewVideos] = useState([]);
    const [key, setKey] = useState(0);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const now = new Date();
    const user = localStorage.getItem('user');
    const itemStr = localStorage.getItem('key');
    if (!itemStr) {
      window.location.replace(envConfig.REACT_ENV + 'kusundacourse');
    }
    const item = JSON.parse(itemStr);
  
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
    
    useEffect(() => {
        fetch(fetchURL)
          .then((res) => { 
            return res.json();
          })
          .then((data) => { 
          console.log(data); 
          setData(data);
          })
          .catch((err) => {
            console.log(err);
          });
      }, []); 

    const handleClick = (lid, total, name, description) => { 
        setTotalVideos([]);
        setNewVideos([]);
        setKey((prevKey) => prevKey + 1);
        setLessonid(lid);
        let i = 1;
        while (i <= total){
           newvideos.push({id: lid+'-'+i+'.mp4'})
           i++;
        }
       setTotalVideos(newvideos);  
       setLessonname(name);
       setDescription(description);
        const list = document.querySelectorAll(".videos");
        for (let i = 0; i < list.length-1; i++) {
            list[i].style.display="none";
          }
        handleClose();
    }

    function LogOut(){
      localStorage.removeItem('user');
      localStorage.removeItem('key');
      window.location.replace(envConfig.REACT_ENV + 'kusundacourse');
    }

return(
<div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>KUSUNDA LESSONS</title>
                    <link rel="canonical" href="https://kusunda.archivenepal.org" />
                    <meta name="description" content="Learn Kusunda Language" />
                </Helmet>

                { (now.getTime() < item.expiry) ?       
                <div>          
                <header id="header" className="alt" style={{ position: 'fixed' }}>
                    <h1><Link to={'/'} className="navbar-brand"><img src="/images/archiveNepal.png" style={{ height: '35px', marginTop: '-8px' }} alt="Archive Nepal - Discover Nepal through its History" /></Link></h1>
                </header>
                <div id="page-wrapper">   
                <section id="main"> 
                <h6 style={{float: 'left', marginLeft: '5px'}}>Welcome {user} !</h6>
                   <div style={{marginTop: '30px'}}>
                      <ThemeProvider theme={theme}>
                        <Button color='nepalBlue' variant='contained' size='small' onClick={LogOut} style={{float: 'right', marginRight: '10px', marginTop: '-40px'}}>Sign out</Button>
                      </ThemeProvider>
                    </div>
                <section className="box special features" style={{textAlign: 'left'}}>
                <div class="video-container" style={{ width: '95%', height: '100%', border: '1px solid white'}}>
                     <h4 id='lessonLabel'>LESSONS</h4> 
                      <div class="listColumn">
                        <div id="lessonsMenu" style={{overflow: 'auto', height: '550px', overflowY: 'auto', padding: '5px'}}>
                            <ol>
                                      {
                                        data.sort((a,b) => a.lessonid > b.lessonid ? 1:-1).map((lesson) => (
                                            <li><span><h5><a href='#'onClick={() => handleClick(lesson.videos, lesson.total, lesson.lessonname, lesson.description)} style={{cursor: 'pointer', fontWeight: 'normal'}}>{lesson.lessonname}</a></h5></span></li>
                                        ))
                                    }
                            </ol>
                        </div>
                    </div>

                    <div class="videosColumn">
                    <Button onClick={handleOpen} variant='outlined' id='lessonsBtn' style={{width: '100%', marginBottom: '60px'}}>LESSONS</Button>
                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style} style={{ width: '350px', height: '500px', overflow: 'auto' }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">LESSONS</Typography>
                              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <ol>
                                {
                                  data.sort((a,b) => a.lessonid > b.lessonid ? 1:-1).map((lesson) => (
                                    <li><span><h5><a href='#'onClick={() => handleClick(lesson.videos, lesson.total, lesson.lessonname, lesson.description)} style={{cursor: 'pointer', fontWeight: 'normal'}}>{lesson.lessonname}</a></h5></span></li>
                                  ))
                                }
                                </ol>
                              </Typography>
                          </Box>
                        </Modal> 
                        <h3 style={{textAlign: 'center', marginTop: '-50px'}}>{lessonname}</h3>
                        <p style={{padding: '20px'}}>{description}</p>
                        <div id="lessonsVideo" style={{overflow: 'auto', height: '550px'}}>   
                          <div id={lessonid}> 
                          {
                            videoslist.map((videos) => (
                              <span>
                                <h1 style={{textAlign: 'center'}}>{videos.id.replace(".mp4", "").replace("lesson", "lesson ").replace("-"," [Part ").toUpperCase()} of {videoslist.length}]</h1>
                                <video width="75%" height="65%" key={key} class="videos" controls style={{display: 'block', margin: '0 auto', marginBottom: '30px', border: '1px solid #f0f0f0'}}>
                                <source src={lessonURL+videos.id} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video><br />
                              </span>
                            ))
                          } 
                          </div>
                        </div>
                    </div>
                    </div>  
                    </section>
                    </section>
                </div> </div>: Expired() }

              
            </div>
    );
  }

