import React, { useState } from 'react';
import axios from 'axios';
import envConfig from '../config/env.json';

function RegistrationForm() {
  
  const signupUser = localStorage.getItem('signupuser').replace(/['"]+/g, '');
  const signupEmail = localStorage.getItem('signupemail').replace(/['"]+/g, '');
  const now = new Date();
  const item = {
    expiry: now.getTime() + 3600000,
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    axios.put('https://y6sfd318o5.execute-api.us-east-1.amazonaws.com/v1/users', formData)
      .then((response) => {
        //console.log('Success:', response.data);
        alert('Thanks for registering to learn the Kusunda language. You will be redirected to the lessons page.');
        localStorage.removeItem('signupuser');
        localStorage.removeItem('signupemail');
        localStorage.removeItem('signupkey');
        localStorage.setItem('key', JSON.stringify(item))
       localStorage.setItem('user', JSON.stringify(signupUser))
       localStorage.setItem('email', JSON.stringify(signupEmail))
        window.location.replace(envConfig.REACT_ENV + 'kusundalessons');
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Due to technical issues, we cannot register you. Please contact the administrator at contact@archivenepal.org for assistance.');
      });
  };



  const [formData, setFormData] = useState({
    username: signupEmail,
    name: signupUser,
    accesslink: 'kusundalessons',
    // Add other form fields as needed
  });

  return (
    <div id="page-wrapper">
      <section id="main" className="container">
        <section className="box special features">
          <div style={{ maxWidth: 'auto', maxHeight: 'auto', marginTop: '20px', padding: '15px' }}>
          <h2>Register to learn the Kusunda Language</h2>    
            <form onSubmit={handleSubmit}>
              <h4>Thank you, {signupUser}, for your interest in learning and helping preserve the Kusunda language! To complete your registration, please click the "Sign Up" button below. Your {signupEmail} Gmail address will be used for the registration process. We truly appreciate your support in safeguarding one of the most endangered languages in the world.</h4>
              <img src='https://archivenepal.s3.us-east-1.amazonaws.com/images/kusundaStudents.jpg' style={{width: '365px'}} /><br />
              <p style={{fontSize: '12px', fontStyle: 'Italic'}}>Image: Kusunda students who undertook the in person Advanced Level Course with Mr. Uday Raj Aaley</p>
              <button type="submit" style={{ marginTop: '20px'}}>Sign up</button>
            </form>
          </div>
        </section>
      </section>
    </div>
  );
}

export default RegistrationForm;